<template>
  <div id="center">
    <div class="up">
      <div
        v-for="item in titleItem"
        :key="item.title"
        class="bg-color-black item"
      >
        <p class="ml-3 colorBlue fw-b fs-xl">{{item.title}}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          ></dv-digital-flop>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">今日维修工单</span>
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>保养总数</span>
          <center-chart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :color-obj="rate[0].colorData"
          ></center-chart>
        </div>
        <div class="item bg-color-black">
          <span>完成率</span>
          <dv-percent-pond :config="config" style="width:200px;height:100px;margin-top: 50px"></dv-percent-pond>
        </div>

        <!--        <div class="water">-->
        <!--          <dv-water-level-pond class="dv-wa-le-po" :config="water"></dv-water-level-pond>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import CenterChart from "@/components/echart/center/centerChartRate";
  export default {
    components: {
      CenterChart,
    },
    data() {
      return {
        titleItem:[],
        config: { value: 66,
                  localGradient: true},
        water: {
          data: [24, 45],
          shape: "roundRect",
          formatter: "{value}%",
          waveNum: 3,
        },
        // 通过率和达标率的组件复用数据
        rate: [
          {
            id: "centerRate1",
            tips: 60,
            colorData: {
              textStyle: "#3fc0fb",
              series: {
                color: ["#00bcd44a", "transparent"],
                dataColor: {
                  normal: "#03a9f4",
                  shadowColor: "#97e2f5",
                },
              },
            },
          },
          {
            id: "centerRate2",
            tips: 40,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
        ],
        data:{},
      };
    },
    computed: {
      defaultOption() {
        return {
          step: 1, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      },
    },
    mounted() {
      this.getData();
    },
    methods:{
      getData() {
        let params = this.params;
        let http = this.$http.get("big-screen/total/elevator-quantity", params);
        http.then(data => {
          this.data = data;
          this.titleItem= [
            {
              title: "总维保台量",
              number: {
                number: [this.data.totalQuantity],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
            {
              title: "免保台量",
              number: {
                number: [this.data.freeMaintenanceQuantity?this.data.freeMaintenanceQuantity:0],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
            {
              title: "可遥监台量",
              number: {
                number: [this.data.remoteMonitorQuantity],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
            {
              title: "在线台量",
              number: {
                number: [this.data.onlineQuantity?this.data.onlineQuantity:0],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
            {
              title: "今日急修台量",
              number: {
                number: [this.data.todayMaintenanceQuantity?this.data.todayMaintenanceQuantity:0],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
            {
              title: "今日保养台量",
              number: {
                number: [this.data.todayMaintenanceQuantity?this.data.todayMaintenanceQuantity:0],
                toFixed: 1,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 26,
                },
              },
            },
          ];
          this.rate=[
            {
              id: "centerRate1",
              tips: (data.regularMaintenanceQuantity/(data.totalQuantity)).toFixed(2)*100,
              colorData: {
                textStyle: "#3fc0fb",
                series: {
                  color: ["#00bcd44a", "transparent"],
                  dataColor: {
                    normal: "#03a9f4",
                    shadowColor: "#97e2f5",
                  },
                },
              },
            },
            {
              id: "centerRate2",
              tips: parseInt(data.asNeededMaintenanceQuantity/(data.totalQuantity)*100,10),
              colorData: {
                textStyle: "#67e0e3",
                series: {
                  color: ["#faf3a378", "transparent"],
                  dataColor: {
                    normal: "#ff9800",
                    shadowColor: "#fcebad",
                  },
                },
              },
            },
          ];
          // }
          // this.status = "finish";
        }).catch(() => {
          // this.status = "error";
        });
        let get = this.$http.get("big-screen/today/repair-order", params);
        get.then(data => {
          console.log(data,237);
          // this.data = data;
          const arr=[];
          data.forEach(item=>{
            // let list =[];
            // for (let [key,value] of Object.entries(item)) {
            //   if (key==="reportTime"||key==="planDate") {
            //     list.push(this.$moment(value).format("hh:mm:ss"));
            //   }else if (key!=="taskStatus") {
            //     list.push(value);
            //   }
            // }
            // arr.push(list);
            arr.push([item.realEstateName+item.buildingName,item.elevatorName,item.taskStatusDesc,item.workerName]);
          });
          console.log(arr,250);
          this.config={
            header: ["楼盘楼宇","电梯","完成状态","维保人员"],
            data: arr,
            // index: true,
            // columnWidth: [50],
            align: ["center"],
          };

          console.log((data.asNeededMaintenanceQuantity/(data.regularMaintenanceQuantity+data.asNeededMaintenanceQuantity))*100,261);
          // }
          // this.status = "finish";
        }).catch(() => {
          // this.status = "error";
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .seamless-warp {
    height: 200px;
    //opacity: 0.5;

    //line-height: 20px;
    overflow: hidden;
  }
  .cardTitle{
    color: white;
  }
  .content{
    color: white;

  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 250px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
